$app-background: #4D4D4D;
$controls-background: #121212;
$controls-text: rgba(white, 0.87);
$dark-background-1410: #070707;
$dark-background-2018: #132532;
$dark-metal: #575652;
$dark-metal-1410: #333;
$metal-edge: rgba(#2B2B2B, 0.8);
$gold-trim: #FFEAA6;
$gold-trim-1410: #C5A987;
$gold-trim-2018: #FFECAE;
$sky-color-1410: #73AABF;
$sky-color-2018: #2F9DE7;
