@import "./colors";

:root {
  --background: #{$app-background};
  --font-scaler: 1;
  --gold-trim: #{$gold-trim};
  --gold-trim-2018: #{$gold-trim-2018};
  --mfvh: 100vh; // mobile full height
  --mvh: 1vh; // mobile vh-like unit
  --sky-color-2018: #{$sky-color-2018};
}

html {
  height: 100%;
  margin: 0;
  overflow: hidden;
  width: 100%;
}

body {
  background-color: var(--background);
  font: 14px sans-serif;
  height: var(--mfvh);
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100vw;
}

.unequalHourText {
  font-size: 30px;
  text-anchor: middle;
  fill: rgb(black, 55%);
  font-family: HorologiumII, sans-serif;
}

.orig-1410 .unequalHourText {
  fill: rgb(black, 90%);
}

.post-2018 .unequalHourText {
  fill: rgb(black, 67%);
}

#globe-host {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.5s ease-in-out;
  width: 100%;

  canvas {
    height: 100% !important;
    left: 0;
    position: absolute;
    top: 0;
    width: 100% !important;
  }
}

@media
  screen and (orientation: landscape) and (max-height: 340px),
  screen and (orientation: landscape) and (max-width: 630px),
  screen and (orientation: portrait) and (max-width: 360px),
  screen and (orientation: portrait) and (max-height: 740px) {
  html {
    overflow: auto;
  }

  body {
    position: static;
  }
}

input {
  background-color: #121212;
  color: #DDD;
}

::placeholder {
  color: rgba(255, 255, 255, 33%);
  font-style: italic;
}

.p-checkbox .p-checkbox-box, .p-dropdown, .p-inputtext, .p-radiobutton .p-radiobutton-box {
  border-color: #555;
}

.p-menu, .p-tieredmenu {
  width: 22em;
}

.p-menu-list {
  min-width: 20em;
  max-height: calc(90 * var(--mfvh));
  overflow-y: auto;
}

.p-tieredmenu .p-submenu-list {
  min-width: 50% !important;
}

.p-tieredmenu .p-menuitem-active > p-tieredmenusub > .p-submenu-list.p-submenu-list-flipped {
  left: -50% !important;
}

#alt-language-menu {
  bottom: 1rem;
  display: none;
  left: calc(50% - 6em);
  margin: auto;
  position: fixed;
  top: 1rem;
  width: 12rem;
  z-index: 1;

  .p-menu {
    width: 12rem;
  }

  .closer {
    color: white;
    cursor: pointer;
    padding: 0.5rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
}

@media screen and (max-width: 820px) {
  .p-tieredmenu .p-menuitem-active > p-tieredmenusub > .p-submenu-list {
    right: 2em !important;

    &.p-submenu-list-flipped {
      left: -2em !important;
    }
  }
}

.p-dialog-content {
  max-width: 95vw;
}

.p-button {
  font-weight: bold;
  padding: 0.33rem 0.66rem;
}

.p-checkbox-label, .p-radiobutton-label {
  line-height: unset;
  margin-left: 0.33em !important;
  margin-right: 0.5em !important;
}

span.p-menuitem-text {
  white-space: nowrap;
}

.pi.pi-circle {
  opacity: 0;
}

.icon-disabled {
  cursor: default;
  opacity: 0.33;
  pointer-events: none;
}

.clear-icon {
  cursor: pointer;
  display: inline-block;
  margin: -0.33em;
  padding: 0.33em;
}

.tbw-dark-mode {
  .tbw-dse-header {
    background-color: #121212;
    border-color: #555;
  }

  .tbw-dse-closer {
    background-color: #121212;
  }

  .tbw-dse-footer {
    background-color: #242424;
  }

  .tbw-dse-wrapper:not(.tbw-dse-explicit-focus-outline) {
    border-color: #555;
  }

  .tbw-dse-divider {
    background-color: #999;
  }

  .tbw-dse-bad-value {
    color: #C00 !important;
  }

  .tbw-disabled-arrow-color { color: #060; stroke: #060; }
  .tbw-disabled-background { background-color: #242424; fill: #242424; }
  .tbw-disabled-text { color: #777; stroke: #777; }
  .tbw-indicator-text { color: #64B5F6; stroke: #64B5F6; }
  .tbw-normal-background { background-color: #121212; fill: #121212; }
  .tbw-normal-text { color: #DDD; stroke: #DDD; }
  .tbw-selected-background { background-color: #DDD; fill: #DDD; }
  .tbw-selected-text { color: #121212; stroke: #121212; }
  .tbw-spinner-fill { background-color: #444; fill: #444; }
  .tbw-view-only-background { background-color: #0A0; fill: #0A0; }
  .tbw-view-only-text { color: black; stroke: black; }
  .tbw-warning-text { color: #E45; stroke: #E45; }
  .tbw-warning-background { background-color: #B80; fill: #B80; }

  .tbw-dse-fade { opacity: 0.28; }
  .tbw-view-only-text.tbw-dse-fade { opacity: 0.2; }

  @keyframes tbw-dse-blinker-dark {
    0%, 100% { background-color: #DDD; color: #121212; }
    10%, 20% { background-color: #DDD; color: #121212; }
    50%, 60%, 70% { background-color: transparent; color: #DDD; }
  }

  .tbw-dse-steady-cursor {
    background-color: #DDD;
    color: #121212;
  }
}

.eclipticCircle {
  stroke: $gold-trim;
  stroke-width: 4px;
  fill: none;
}

.eclipticDial {
  stroke: $gold-trim;
  stroke-width: 3px;
  fill: none;
}

.outerRingText {
  font-size: 60px;
  text-anchor: middle;
  fill: $gold-trim;
}

.timeText {
  font-size: 50px;
  text-anchor: middle;
  fill: $gold-trim;
}

.post-2018 {
  .timeText, .outerRingText {
    fill: $gold-trim-2018;
  }

  .eclipticCircle, .eclipticDial {
    stroke: $gold-trim-2018;
  }
}

.orig-1410 {
  .timeText, .outerRingText {
    fill: $gold-trim-1410;
  }

  .eclipticCircle, .eclipticDial {
    stroke: $gold-trim-1410;
  }
}

.four-iv {
  opacity: 1;
}

.four-iiii {
  opacity: 0;
}

.four-as-iiii {
  .four-iv {
    opacity: 0;
  }

  .four-iiii {
    opacity: 1;
  }
}

.orientation-changing {
  .controls, svg {
    transition-duration: unset !important;
    transition-property: unset !important;
    transition-timing-function: unset !important;
  }
}
